import { forEach } from './util';
import { inView } from './scroll-listener';

export const enhancer = (el) => {

  document.addEventListener('scroll', (e) => {
    if (window.scrollY > 300) {
      el.classList.add('site-header--invert');
    } else {
      el.classList.remove('site-header--invert');
    }
  });

  /* @todo */
  const imgElms = document.querySelectorAll('.media-text img');
  document.addEventListener('scroll', (e) => {
    forEach(imgElms, (elm) => {
      if (inView(elm) > 0) {
        elm.classList.add('image--inview');
      }
    });
  });
};
